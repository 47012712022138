<nb-card>
  <nb-card-header status="success">
    <label style="font-size: 140%;">Pending Applications</label>
  </nb-card-header>

  <nb-card-body>
    <form [formGroup]="searchForm" class="row">

      <div class="col-xl-4 form-group">
        <label>App_ID</label><br>
        <input nbInput formControlName="idCtrl" placeholder="App_ID"
          [ngClass]="{'form-control-danger': searchForm.controls.idCtrl.invalid && (searchForm.controls.idCtrl.dirty || searchForm.controls.idCtrl.touched)}">
      </div>

      <div class="col-xl-4 form-group">
        <label>Name</label><br>
        <input nbInput formControlName="nameCtrl" [(ngModel)]="name" placeholder="name"
          [ngClass]="{'form-control-danger': searchForm.controls.nameCtrl.invalid && (searchForm.controls.nameCtrl.dirty || searchForm.controls.nameCtrl.touched)}">
      </div>

      <div class="col-xl-4 form-group">
        <label>Email ID</label><br>
        <input nbInput formControlName="emailCtrl" [(ngModel)]="email" placeholder="email"
          [ngClass]="{'form-control-danger': searchForm.controls.emailCtrl.invalid && (searchForm.controls.emailCtrl.dirty || searchForm.controls.emailCtrl.touched)}">
      </div>

      <div class="col-xl-4 form-group">
        <label>Enroll No</label><br>
        <input nbInput formControlName="EnrollnoCtrl" placeholder="Enrollno"
          [ngClass]="{'form-control-danger': searchForm.controls.EnrollnoCtrl.invalid && (searchForm.controls.EnrollnoCtrl.dirty || searchForm.controls.EnrollnoCtrl.touched)}">
      </div>

      <div class="col-xl-2">
        <button (click)="search()">Search</button>
      </div>
    </form>

    <br>
    <div class="row">
      <div class="col-xl-4" style="margin-bottom: 5%;">
        <input type="text" [value]="filterText" [placeholder]="filterPlaceholder" [formControl]="filterInput" />
        <button mat-button *ngIf="filterText" matSuffix mat-icon-button aria-label="Clear" (click)="filterText=''">
          <mat-icon>close</mat-icon>
        </button>
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      </div>
      <!-- <div class="col-lg-3 ">
            <mat-form-field>
              <mat-select placeholder="select Year" [(ngModel)]="selectedYear" name="selectedYear" id="yearCtrl"
                (ngModelChange)="filterYear($event)">
                <mat-option value="2019">2019-2020</mat-option>
                <mat-option value="2020">2020-2021</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
    </div>
    <p-tabView [(activeIndex)]="index">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div (click)="changetab($event,'0')">Pending Application</div>
        </ng-template>
        <button style="float: right;" nbButton status="success" (click)="downloadExcel('pending')">
          <div style="font-size: 86%;">
            Download Excel
          </div>
        </button>
        <div class="wrapper">
          <h5 style="text-align: left;"><b>Total student count: {{studentLength?.totalLength}} </b></h5><br /><br />
          <!-- </div> -->
          <!-- <h5><b>Filtered student count: </b></h5>{{studentdata?.length}} -->
          <table id="selectedColumn">
            <thead>
              <tr>
                <th>App_ID</th>
                <th>Enroll No.</th>
                <th>Name As Per Marksheet</th>
                <th>Email Address</th>
                <th>Application Date</th>
                <th>Action</th>
                <th>Verify</th>
                <th>Note
                  <!-- <button nbButton size="small" status="primary" (click)="megecertificate(selected_value);">Merge </button> -->

                </th>
                <th>Rejected</th>
                <!-- <th>Application Tracker</th>
                    <th>Application Status</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let student of studentdata | filter:{name:filterText ,email:filterText , id:filterText}  | paginate: { itemsPerPage: 10, totalItems: totalLength, currentPage: p } ;let i=index;">
                <td>{{student?.id}}</td>
                <td>{{student?.enroll_no}}</td>
                <td>{{student?.fullname}}</td>
                <td>{{student?.email}}</td>
                <td>{{student?.created_at}}</td>
                <td> <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                    (click)="handleClick(student.user_id,student.id,'pending')"></i><br>
                  <button nbButton outline size="small"
                    *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary"
                    (click)="verifyOtp('0', '0', student.id);">Verify</button>
                  <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'"
                    (click)="status('inactive',student.id);">inactive</button>
                  <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'"
                    (click)="status('active',student.id);">active</button>
                </td>
                <td>
                  <button button class="btn btn-primary"
                    (click)="verify(student.id,student.user_id,'pending',email_admin)">Verify</button><br>

                </td>
                <td>
                  <i class="fa ion-clipboard" status="info" nbPopover="View More" nbPopoverMode="hover"
                    (click)="addNotes(student.id,student.user_id,email_admin)"></i><br>
                  <!-- <nb-checkbox 
                    (checkedChange)="toggle($event,student?.user_id,student?.id)"></nb-checkbox> -->
                </td>

                <td> <i class="fa ion-close-round" status="info" nbPopover="Reject" nbPopoverMode="hover"
                    (click)="Reject(student.id,student.user_id,'Reject',email_admin)"></i><br></td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="Rtable Rtable--5cols Rtable--collapse">
                <div class="Rtable-row Rtable-row--head">
                  <div class="Rtable-cell date-cell column-heading">App_ID</div>
                  <div class="Rtable-cell date-cell column-heading">Name As Per Marksheet</div>
                  <div class="Rtable-cell topic-cell column-heading">Email Address</div>
                  <div class="Rtable-cell date-cell column-heading">Application Tracker</div>
                  <div class="Rtable-cell date-cell column-heading">Application Status</div>
                  <div class="Rtable-cell date-cell column-heading">Application Date</div>
                  <div class="Rtable-cell date-cell column-heading">Action</div>
                  <div class="Rtable-cell date-cell column-heading">Verify</div>
                  <div class="Rtable-cell date-cell column-heading">Rejected</div>
                  <div class="Rtable-cell date-cell column-heading">Note</div>
                  <div class="Rtable-cell date-cell column-heading">Reject</div>


                </div> -->

          <!-- <div class="Rtable-row is-striped" *ngFor="let student of studentdata | filter:{name:filterText ,email:filterText , id:filterText}  | paginate: { itemsPerPage: 10, totalItems: totalLength, currentPage: p }">
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">App_ID</div>
                    <div class="Rtable-cell--content date-content">{{student?.id}}
                    </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Name As Per Marksheet</div>
                    <div class="Rtable-cell--content date-content">{{student?.fullname}}</div>
                  </div>
                  <div class="Rtable-cell topic-cell">
                    <div class="Rtable-cell--heading">Email Address</div>
                    <div class="Rtable-cell--content title-content">{{student?.email}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Application Tracker</div>
                    <div class="Rtable-cell--content date-content">{{student?.tracker}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Application Status</div>
                    <div class="Rtable-cell--content date-content">{{student?.status}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Application Date</div>
                    <div class="Rtable-cell--content date-content">{{student?.created_at}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Action</div>
                    <div class="Rtable-cell--content date-content">
                      <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                      (click)="handleClick(student.user_id,student.id,'pending')"></i><br>
                      <button nbButton outline size="small" *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary" (click)="verifyOtp('0', '0', student.id);">Verify</button>
                      <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'" (click)="status('inactive',student.id);">inactive</button>
                      <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'" (click)="status('active',student.id);">active</button>
                    </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Verify</div>
                    <div class="Rtable-cell--content date-content">
                     
                      <button button class="btn btn-danger" (click)="Reject(student.id,student.user_id,'Reject',email_admin)"><i class="fa fa-window-close" aria-hidden="true"></i></button>
                      <button button class="btn btn-primary" (click)="verify(student.id,student.user_id,'pending',email_admin)">Verify</button><br>

                    </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Rejected</div>
                    <div class="Rtable-cell--content date-content">
                     
                      <button button class="btn btn-danger" (click)="Reject(student.id,student.user_id,'Reject',email_admin)"><i class="fa fa-window-close" aria-hidden="true"></i></button>
                      <i class="fa ion-close-round" status="info" nbPopover="Reject" nbPopoverMode="hover"
                      (click)="Reject(student.id,student.user_id,'Reject',email_admin)"></i><br>
                    </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Note</div>
                    <div class="Rtable-cell--content date-content">
                      <i class="fa ion-clipboard" status="info" nbPopover="View More" nbPopoverMode="hover"
                      (click)="addNotes(student.id,student.user_id,email_admin)"></i><br>
                    </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Profile Completeness</div>
                    <div class="Rtable-cell--content date-content">
                        <nb-progress-bar [value]="student.profile_completeness" outline status="info" [displayValue]="true">
                        </nb-progress-bar>
                    </div>
                  </div>
                </div>
              </div> -->
          <div *ngIf="studentLength?.totalLength == 0">
            <span style="color : red; font-size: 18px;">No Data Available</span>
          </div>
        </div>

      </p-tabPanel>

      <p-tabPanel>
        <ng-template pTemplate="header">
          <div (click)="changetab($event,'1')">Requested Application</div>
        </ng-template>
        <button style="float: right;" nbButton status="success" (click)="downloadExcel('Requested')">
          <div style="font-size: 86%;">
            Download Excel
          </div>
        </button>
        <div class="wrapper">
          <h5 style="text-align: left;"><b>Total student count: {{student_requested_Length?.totalLength}} </b></h5>
          <br /><br />
          <table id="selectedColumn">
            <thead>
              <tr>
                <th>App_ID</th>
                <th>Enroll No.</th>
                <th>Name As Per Marksheet</th>
                <th>Email Address</th>
                <th>Application Date</th>
                <th>Action</th>
                <th>Note</th>
                <th>Verify</th>
                <!-- <th></th>
                    <th></th> -->

              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let student of studentdata_requested | filter:{name:filterText , email:filterText, id:filterText }  | paginate: { itemsPerPage: 10, totalItems: totalLength, currentPage: p }">
                <td>{{student?.id}}</td>
                <td>{{student?.enroll_no}}</td>
                <td>{{student?.fullname}}</td>
                <td>{{student?.email}}</td>
                <!-- <td>{{student?.tracker}}</td> -->
                <td>{{student?.created_at}}</td>
                <td>
                  <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                    (click)="handleClick(student.user_id,student.id,'requested')"></i><br>
                  <button nbButton outline size="small"
                    *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary"
                    (click)="verifyOtp('0', '0', student.id);">Verify</button>
                  <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'"
                    (click)="status('inactive',student.id);">inactive</button>
                  <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'"
                    (click)="status('active',student.id);">active</button>
                </td>

                <td>
                  <i class="fa ion-clipboard" status="info" nbPopover="View More" nbPopoverMode="hover"
                    (click)="addNotes(student.id)"></i><br>

                </td>
                <td>
                  <button (click)="verify2(student.id,student.user_id,'requested',email_admin)">To Changed</button><br>

                </td>
              </tr>
            </tbody>
          </table>

          <!-- <h5><b>Total student count: {{student_requested_Length?.totalLength}} </b></h5><br/><br/>
              <h5><b>Filtered student count: </b></h5>{{studentdata?.length}}
              <div class="Rtable Rtable--5cols Rtable--collapse">
                <div class="Rtable-row Rtable-row--head">
                  <div class="Rtable-cell date-cell column-heading">App_ID</div>
                  <div class="Rtable-cell date-cell column-heading">Name As Per Marksheet</div>
                  <div class="Rtable-cell topic-cell column-heading">Email Address</div>
                  <div class="Rtable-cell date-cell column-heading">Application Tracker</div>
                  <div class="Rtable-cell date-cell column-heading">Application Status</div>
                  <div class="Rtable-cell date-cell column-heading">Application Date</div>
                  <div class="Rtable-cell date-cell column-heading">Action</div>
                  <div class="Rtable-cell date-cell column-heading">Note</div>

                  <div class="Rtable-cell date-cell column-heading">Verify</div>
                  <div class="Rtable-cell date-cell column-heading">Notes</div>
                </div>
                
                <div class="Rtable-row is-striped" *ngFor="let student of studentdata_requested | filter:{name:filterText , email:filterText, id:filterText }  | paginate: { itemsPerPage: 10, totalItems: totalLength, currentPage: p }" >
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">App_ID</div>
                    <div class="Rtable-cell--content date-content">{{student?.id}}
                    </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Name As Per Marksheet</div>
                    <div class="Rtable-cell--content date-content">{{student?.fullname}}</div>
                  </div>
                  <div class="Rtable-cell topic-cell">
                    <div class="Rtable-cell--heading">Email Address</div>
                    <div class="Rtable-cell--content title-content">{{student?.email}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Application Tracker</div>
                    <div class="Rtable-cell--content date-content">{{student?.tracker}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Application Status</div>
                    <div class="Rtable-cell--content date-content">{{student?.status}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Application Date</div>
                    <div class="Rtable-cell--content date-content">{{student?.created_at}}</div>
                  </div>
                 
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Action</div>
                    <div class="Rtable-cell--content date-content">
                      <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                      (click)="handleClick(student.user_id,student.id,'requested')"></i><br>
                      <button nbButton outline size="small" *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary" (click)="verifyOtp('0', '0', student.id);">Verify</button>
                      <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'" (click)="status('inactive',student.id);">inactive</button>
                      <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'" (click)="status('active',student.id);">active</button>
                    </div>

                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Note</div>
                    <div class="Rtable-cell--content date-content">
                      <i class="fa ion-clipboard" status="info" nbPopover="View More" nbPopoverMode="hover"
                      (click)="addNotes(student.id)"></i><br>
                      <div style="font-weight: bold;"><button nbButton (click)="ResendApplication(student?.user_id,student?.id)" >Resend</button><br/>
</div>
                    </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Verify</div>
                    <div class="Rtable-cell--content date-content">
                      <button button class="btn btn-primary" (click)="verify(student.id,student.user_id,'requested',email_admin)">Verify</button><br>

                    </div>
                  </div>

                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Notes</div>
                    <div class="Rtable-cell--content date-content">
                      <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                      (click)="showNotes(student.id,student.user_id,student.notes)"></i><br>
                    </div>
                  </div>
                </div>
              </div> -->
          <div *ngIf="student_requested_Length?.totalLength == 0">
            <span style="color : red; font-size: 18px;">No Data Available</span>
          </div>
        </div>


      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div (click)="changetab($event,'3')">Changed Application</div>
        </ng-template>
        <button style="float: right;" nbButton status="success" (click)="downloadExcel('change')">
          <div style="font-size: 86%;">
            Download Excel
          </div>
        </button>
        <div>
          <h5 style="text-align: left;"><b>Total student count: {{student_changed_Length?.totalLength}} </b></h5>
          <br /><br />
          <table>
            <thead>
              <tr>
                <th>App_ID</th>
                <th>Enroll No</th>
                <th>Name As Per Marksheet</th>
                <th>Email Address</th>
                <th>Application Date</th>
                <th>Action</th>
                <th>Verify</th>
                <th>Rejected</th>
                <th>Note</th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="let student of studentdata_changed | filter:{name:filterText, email:filterText, id:filterText}  | paginate: { itemsPerPage: 10, totalItems: totalLength, currentPage: p }">
                <td>{{student?.id}}</td>
                <td>{{student?.enroll_no}}</td>
                <td>{{student?.fullname}}</td>
                <td>{{student?.email}}</td>
                <td>{{student?.created_at}}</td>
                <td>
                  <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                    (click)="handleClick(student.user_id,student.id,'changed')"></i><br>
                  <button nbButton outline size="small"
                    *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary"
                    (click)="verifyOtp('0', '0', student.id);">Verify</button>
                  <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'"
                    (click)="status('inactive',student.id);">inactive</button>
                  <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'"
                    (click)="status('active',student.id);">active</button>
                </td>
                <td>
                  <button button class="btn btn-primary"
                    (click)="verify(student.id,student.user_id,'changed',email_admin)">Verify</button><br>
                </td>
                <td>
                  <i class="fa ion-close-round" status="info" nbPopover="Reject" nbPopoverMode="hover"
                    (click)="Reject(student.id,student.user_id,'Reject',email_admin)"></i><br>
                  <!-- <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                      (click)="verify(student.id,student.user_id,'changed')"></i><br> -->
                </td>

                <td>
                  <i class="fa ion-clipboard" status="info" nbPopover="View More" nbPopoverMode="hover"
                    (click)="addNotes(student.id)"></i><br>
                </td>

              </tr>

            </tbody>
          </table>
          <!-- </div>
              <h5><b>Total student count: {{student_changed_Length?.totalLength}} </b></h5><br/><br/> -->
          <!-- <h5><b>Filtered student count: </b></h5>{{studentdata?.length}} -->
          <!-- <div class="Rtable Rtable--5cols Rtable--collapse">
                <div class="Rtable-row Rtable-row--head">
                  <div class="Rtable-cell date-cell column-heading">App_ID</div>
                  <div class="Rtable-cell date-cell column-heading">Name As Per Marksheet</div>
                  <div class="Rtable-cell topic-cell column-heading">Email Address</div>
                  <div class="Rtable-cell date-cell column-heading">Application Tracker</div>
                  <div class="Rtable-cell date-cell column-heading">Application Status</div>
                  <div class="Rtable-cell date-cell column-heading">Application Date</div>
                  <div class="Rtable-cell date-cell column-heading">Action</div>
                  <div class="Rtable-cell date-cell column-heading">Verify</div>
                  <div class="Rtable-cell date-cell column-heading">Note</div>

                </div>
                
                <div class="Rtable-row is-striped" *ngFor="let student of studentdata_changed | filter:{name:filterText, email:filterText, id:filterText}  | paginate: { itemsPerPage: 10, totalItems: totalLength, currentPage: p }">
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">App_ID</div>
                    <div class="Rtable-cell--content date-content">{{student?.id}}
                    </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Name As Per Marksheet</div>
                    <div class="Rtable-cell--content date-content">{{student?.fullname}}</div>
                  </div>
                  <div class="Rtable-cell topic-cell">
                    <div class="Rtable-cell--heading">Email Address</div>
                    <div class="Rtable-cell--content title-content">{{student?.email}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Application Tracker</div>
                    <div class="Rtable-cell--content date-content">{{student?.tracker}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Application Status</div>
                    <div class="Rtable-cell--content date-content">{{student?.status}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Application Date</div>
                    <div class="Rtable-cell--content date-content">{{student?.created_at}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Action</div>
                    <div class="Rtable-cell--content date-content">
                      <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                      (click)="handleClick(student.user_id,student.id,'changed')"></i><br>
                      <button nbButton outline size="small" *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary" (click)="verifyOtp('0', '0', student.id);">Verify</button>
                      <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'" (click)="status('inactive',student.id);">inactive</button>
                      <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'" (click)="status('active',student.id);">active</button>
                    </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Verify</div>
                    <div class="Rtable-cell--content date-content">
                      <i class="fa ion-close-round" status="info" nbPopover="Reject" nbPopoverMode="hover"
                      (click)="Reject(student.id,student.user_id,'Reject',email_admin)"></i><br>
                      <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                      (click)="verify(student.id,student.user_id,'changed')"></i><br> -->
          <!-- <button button class="btn btn-primary" (click)="verify(student.id,student.user_id,'changed',email_admin)">Verify</button><br>
                    </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Note</div>
                    <div class="Rtable-cell--content date-content">
                      <i class="fa ion-clipboard" status="info" nbPopover="View More" nbPopoverMode="hover"
                      (click)="addNotes(student.id)"></i><br>
                    </div>
                      </div> -->
          <!-- <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Profile Completeness</div>
                    <div class="Rtable-cell--content date-content">
                        <nb-progress-bar [value]="student.profile_completeness" outline status="info" [displayValue]="true">
                        </nb-progress-bar>
                    </div>
                  </div> -->
          <!-- </div> -->
          <div *ngIf="student_changed_Length?.totalLength == 0">
            <span style="color : red; font-size: 18px;">No Data Available</span>
          </div>
        </div>

      </p-tabPanel>

      <p-tabPanel>
        <ng-template pTemplate="header">
          <div (click)="changetab($event,'4')">Rejected Application</div>
        </ng-template>
        <button style="float: right;" nbButton status="success" (click)="downloadExcel('reject')">
          <div style="font-size: 86%;">
            Download Excel
          </div>
        </button>
        <div>
          <h5 style="text-align: left;"><b>Total student count: {{studentdata_rejected_Length?.totalLength}} </b></h5>
          <br /><br />

          <table id="selectedColumn">
            <thead>
              <tr>
                <th>App_ID</th>
                <th>Name As Per Marksheet</th>
                <th>Email Address</th>
                <th>Reject Reason</th>
                <th>Application Date</th>
                <th>Action</th>
                <th>Verify</th>
                <th>Note</th>

              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let student of studentdata_rejected | filter:{name:filterText, email:filterText, id:filterText}  | paginate: { itemsPerPage: 10, totalItems: totalLength, currentPage: p }">

                <td>{{student?.id}}</td>
                <td>{{student?.fullname}}</td>
                <td>{{student?.email}}</td>
                <td>{{student?.outward}}</td>
                <td>{{student?.created_at}}</td>
                <td>
                  <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                    (click)="handleClick(student.user_id,student.id,'changed')"></i><br>
                  <button nbButton outline size="small"
                    *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary"
                    (click)="verifyOtp('0', '0', student.id);">Verify</button>
                  <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'"
                    (click)="status('inactive',student.id);">inactive</button>
                  <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'"
                    (click)="status('active',student.id);">active</button>
                </td>
                <td>
                  <button button class="btn btn-primary" (click)="verify()">Verify</button><br>

                </td>
                <td>
                  <i class="fa ion-clipboard" status="info" nbPopover="View More" nbPopoverMode="hover"
                    (click)="addNotes(student.id)"></i><br>
                </td>

              </tr>
            </tbody>
          </table>
          <!-- </div> -->
          <!-- <h5><b>Filtered student count: </b></h5>{{studentdata?.length}} -->
          <!-- <div class="Rtable Rtable--5cols Rtable--collapse"> -->
          <!-- <div class="Rtable-row Rtable-row--head">
                  <div class="Rtable-cell date-cell column-heading">App_ID</div>
                  <div class="Rtable-cell date-cell column-heading">Name As Per Marksheet</div>
                  <div class="Rtable-cell topic-cell column-heading">Email Address</div>
                  <div class="Rtable-cell date-cell column-heading">Application Tracker</div>
                  <div class="Rtable-cell date-cell column-heading">Application Status</div>
                  <div class="Rtable-cell date-cell column-heading">Application Date</div>
                  <div class="Rtable-cell date-cell column-heading">Action</div>
                  <div class="Rtable-cell date-cell column-heading">Verify</div>
                  <div class="Rtable-cell date-cell column-heading">Note</div>

                </div> -->

          <!-- <div class="Rtable-row is-striped" *ngFor="let student of studentdata_rejected | filter:{name:filterText, email:filterText, id:filterText}  | paginate: { itemsPerPage: 10, totalItems: totalLength, currentPage: p }">
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">App_ID</div>
                    <div class="Rtable-cell--content date-content">{{student?.id}}
                    </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Name As Per Marksheet</div>
                    <div class="Rtable-cell--content date-content">{{student?.fullname}}</div>
                  </div>
                  <div class="Rtable-cell topic-cell">
                    <div class="Rtable-cell--heading">Email Address</div>
                    <div class="Rtable-cell--content title-content">{{student?.email}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Application Tracker</div>
                    <div class="Rtable-cell--content date-content">{{student?.tracker}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Application Status</div>
                    <div class="Rtable-cell--content date-content">{{student?.status}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Application Date</div>
                    <div class="Rtable-cell--content date-content">{{student?.created_at}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Action</div>
                    <div class="Rtable-cell--content date-content">
                      <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                      (click)="handleClick(student.user_id,student.id,'changed')"></i><br>
                      <button nbButton outline size="small" *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary" (click)="verifyOtp('0', '0', student.id);">Verify</button>
                      <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'" (click)="status('inactive',student.id);">inactive</button>
                      <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'" (click)="status('active',student.id);">active</button>
                    </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Verify</div>
                    <div class="Rtable-cell--content date-content">
                      <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                      (click)="verify(student.id,student.user_id,'changed')"></i><br> -->
          <!-- <button button class="btn btn-primary" (click)="verify()">Verify</button><br>
                    </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Note</div>
                    <div class="Rtable-cell--content date-content">
                      <i class="fa ion-clipboard" status="info" nbPopover="View More" nbPopoverMode="hover"
                      (click)="addNotes(student.id)"></i><br>
                    </div>
                      </div> -->
          <!-- <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">Profile Completeness</div>
                    <div class="Rtable-cell--content date-content">
                        <nb-progress-bar [value]="student.profile_completeness" outline status="info" [displayValue]="true">
                        </nb-progress-bar>
                    </div>
                  </div> -->
          <!-- </div>  -->
          <div *ngIf="studentdata_rejected_Length?.totalLength == 0">
            <span style="color : red; font-size: 18px;">No Data Available</span>
          </div>
        </div>

      </p-tabPanel>

    </p-tabView>

    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
  </nb-card-body>
</nb-card>
<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [style]="{width: '350px', minWidth: '200px'}">
  <nb-card>
    <form [formGroup]="reasonForm">
      <nb-card-body>
        <label><b>KINDLY ENTER A REJECT REASON</b></label>
        <textarea formControlName="reasonCtrl" nbInput placeholder="Reason" rows="4" cols="30"></textarea>
        <button nbButton status="primary" (click)="ok(id, user_id, type)">ok</button>
      </nb-card-body>
    </form>
  </nb-card>
</p-dialog>



<p-dialog [(visible)]="displayVideo2" [modal]="true" [responsive]="true" [style]="{width: '350px', minWidth: '200px'}">
  <nb-card>
    <form [formGroup]="searchForm">
      <nb-card-body class="example-items-rows">
        <label> Enter Outward Number</label>
        <input type="text" formControlName="outward" nbInput placeholder="outwardNumber">
        <button nbButton status="primary" (click)="save()">Save</button>
      </nb-card-body>
    </form>
  </nb-card>
</p-dialog>